import { Component } from '@angular/core';
import {
  personalDataAgreementFile,
  supportEmail,
  supportPhone,
  supportPhoneFormat
} from '../../../constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  supportPhone = supportPhone;
  supportPhoneFormat = supportPhoneFormat;
  supportEmail = supportEmail;
  year = new Date().getFullYear();
  personalDataAgreementFile = personalDataAgreementFile;

  constructor() { }
}

import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  production: true,
  staging: true,
  apiEndpoint: 'https://v2.mirmyfi.ru/api',
  accountUrl: 'https://lk.mirmyfi.ru',
  yaMetrikaId: 69960772,
  ga: 'G-VXGHPDG3B5'
};

import { Inject, Injectable, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {
  private readonly _renderer = this._rendererFactory.createRenderer(this._document.head, null);

  constructor(
    private readonly _title: Title,
    private readonly _meta: Meta,
    private readonly _rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private readonly _document: HTMLDocument
  ) { }

  appendImageLinks(origin: string) {
    this._appendMetaImageLink('og:image', origin);
    this._appendMetaImageLink('twitter:image', origin);
  }

  updatePageLinkTags(): void {
    const url = this._document.location.href;
    let link: HTMLLinkElement = this._document.head.querySelector('link[rel=canonical]');

    if (link) {
      this._renderer.setAttribute(link, 'href', url);
      return;
    }

    link = this._renderer.createElement('link');
    this._renderer.setAttribute(link, 'rel', 'canonical');
    this._renderer.setAttribute(link, 'href', url);
    this._renderer.appendChild(this._document.head, link);

    this._meta.updateTag({ property: 'og:url', content: url });
  }

  setTitle(title: string): MetaDataService {
    this._title.setTitle(title);
    this._meta.updateTag({ name: 'title', content: title });
    this._meta.updateTag({ property: 'og:title', content: title });
    this._meta.updateTag({ property: 'twitter:title', content: title });
    return this;
  }

  setDescription(description: string): MetaDataService {
    this._meta.updateTag({ name: 'description', content: description });
    this._meta.updateTag({ property: 'og:description', content: description });
    this._meta.updateTag({ property: 'twitter:description', content: description });
    return this;
  }

  private _appendMetaImageLink(property: string, origin: string) {
    const tag = this._meta.getTag(`property="${property}"`);
    const url = origin + '/assets/preview-image.webp';

    if (tag) {
      this._renderer.setAttribute(tag, 'content', url);
    } else {
      this._meta.addTag({ property, content: url });
    }
  }
}

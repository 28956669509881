import { NgModule } from '@angular/core';

import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { NgxMetrikaModule, NgxMetrikaService } from '@kolkov/ngx-metrika';
import { environment } from '../environments/environment';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxDadataModule } from '@kolkov/ngx-dadata';

@NgModule({
  imports: [
    AppModule,
    NgxMetrikaModule.forRoot({
      id: environment.yaMetrikaId,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule,
    NgxDadataModule,
    BrowserAnimationsModule
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {
  constructor(
    private _metrika: NgxMetrikaService,
    private _router: Router
  ) {

    let lastUrl = '';

    _router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: RouterEvent) => {
        this._metrika.hit.emit({
          url: event.url,
          hitOptions: {referer: lastUrl || event.url}
        });

        lastUrl = event.url;
      });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'lk'
})
export class LkPipe implements PipeTransform {
  transform(value: string): string {
    if (value[0] !== '/') {
      value = '/' + value;
    }

    return environment.accountUrl + value;
  }

}

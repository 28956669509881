import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ClientServiceType, IFastRegisterRequestData } from '../../../types';
import { FormBuilder, Validators } from '@angular/forms';
import { clientServicePath, PHONE_REGEX, VAT_FORMAT_REGEX } from '../../../constants';
import { HttpHelperService } from '../../../services/http-helper.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../../../environments/environment';
import { timer } from 'rxjs';
import { map, switchAll, take } from 'rxjs/operators';

@Component({
  selector: 'app-fast-offer-form',
  templateUrl: './fast-offer-form.component.html',
  styleUrls: ['./fast-offer-form.component.scss']
})
export class FastOfferFormComponent implements OnInit {
  @Input() serviceType?: ClientServiceType;
  @Input() variant: 'dark' | 'success' = 'success';
  @Input() forGuarantee = false;

  readonly ClientServiceType = ClientServiceType;
  readonly faCircleNotch = faCircleNotch;
  readonly accountUrl = environment.accountUrl;
  readonly smsSent$: EventEmitter<void> = new EventEmitter<void>();
  readonly smsTimer$ = this.smsSent$.pipe(
    map(() => timer(0, 1000).pipe(
      take(31),
      map(i => 30 - i)
    )),
    switchAll()
  );

  finishBtnClass = '';
  nextBtnClass = '';
  step = 1;
  serviceId = 0;
  loading = false;
  needLogin = false;
  error = '';

  readonly form = this._fb.group({
    inn: ['', [Validators.pattern(VAT_FORMAT_REGEX), Validators.required]],
    first_name: ['', [Validators.required]],
    mobile_phone: ['', [Validators.pattern(PHONE_REGEX), Validators.required]],
    email: ['', [Validators.email, Validators.required]],
    sms_code: ['', [Validators.required]],
    term: ['', [Validators.required]],
    sum: ['', [Validators.required]]
  });

  constructor(private _fb: FormBuilder, private _httpHelper: HttpHelperService) {
  }

  ngOnInit() {
    this.finishBtnClass = this.variant === 'dark' ? 'btn-dark' : 'btn-success';
    this.nextBtnClass = this.variant === 'dark' ? 'btn-dark' : 'btn-secondary';

    if (this.forGuarantee) {
      this.step = 0;
    }

    if (this.serviceType) {
      this._setServiceIdFromType(this.serviceType);
    }
  }

  enterInn(): void {
    this.step = this.serviceType ? 3 : 2;
  }

  next(): void {
    this.step++;
  }

  checkEmail(): void {
    if (this.form.get('email').invalid) {
      return;
    }

    this.loading = true;
    this.error = '';
    this.needLogin = false;

    this._httpHelper.checkEmailAvailable(this.form.get('email').value)
      .subscribe(isAvailable => {
        this.loading = false;
        if (isAvailable) {
          this.next();
        } else {
          this.error = 'Этот Email занят. Может, вы уже зарегстрированы?';
          this.needLogin = true;
        }
      });
  }

  selectService(serviceType: ClientServiceType): void {
    this.serviceType = serviceType;
    this.next();

    this._setServiceIdFromType(serviceType);
  }

  send(noCode = false, resend = false) {
    let path = clientServicePath[this.serviceType];
    if (path === 'back-guarantee') {
      path = 'bg';
    }

    this.loading = true;
    this.error = '';

    const data = this._prepareData(noCode);

    this.smsSent$.emit();
    this._httpHelper.fastRegister(path, data).subscribe(
      result => {
        this.loading = false;

        let lkpath: string = result.ua.type;
        if (lkpath === 'bg') {
          lkpath = 'bank-guarantee';
        }

        open(`${environment.accountUrl}/account/${lkpath}/${result.ua.id}/edit?authToken=${result.token}`, '_blank').focus();
      },
      error => {
        this.loading = false;
        if (noCode) {
          if (error.error.sms_code === false) {
            if (!resend) {
              this.step++;
            }
          } else {
            this.error = error.error.mobile_phone.join(', ');
          }
        } else {
          this.error = error.error.sms_code;
        }
      });
  }

  private _setServiceIdFromType(serviceType: ClientServiceType): void {
    this._httpHelper.getServices().subscribe(services => {
      this.serviceId = services.find(s => s.type === serviceType).id;
    });
  }

  private _prepareData(noCode = false): IFastRegisterRequestData {
    const form = this.form.value;
    const data: IFastRegisterRequestData = {
      service: this.serviceId,
      inn: form.inn,
      first_name: form.first_name,
      mobile_phone: form.mobile_phone,
      email: form.email
    };

    if (form.sms_code && !noCode) {
      data.sms_code = form.sms_code;
    }

    if (this.forGuarantee) {
      data.sum = form.sum;

      switch (this.serviceType) {
        case ClientServiceType.GUARANTEE: data.limitation = form.term; break;
        case ClientServiceType.CREDIT: data.credit_term = form.term; break;
      }
    }

    return data;
  }
}

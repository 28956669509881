import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonOptions, NgxMetrikaService } from '@kolkov/ngx-metrika';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root'
})
export class AnalyticService {
  constructor(
    private _router: Router,
    private _yandex: NgxMetrikaService,
    private _google: GoogleAnalyticsService
  ) {}

  eventYandex(target: string, yaOptions: CommonOptions = {}) {
    const yaParams = { url: this._router.url };

    const options = Object.assign({}, yaOptions, {
      params: Object.assign({}, yaOptions.params || {}, yaParams)
    });

    this._yandex.reachGoal.next({ target, options });
  }

  eventGoogle(action, category?, label?, value?) {
    this._google.event(action, category, label, value);
  }
}

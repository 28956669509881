import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { faCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { PHONE_REGEX } from '../../constants';
import { AnalyticService } from '../../services/analytic.service';
import { HttpHelperService } from '../../services/http-helper.service';
import { personalDataAgreementFile } from '../../constants';

@Component({
  selector: 'app-consult-modal',
  templateUrl: './consult-modal.component.html',
  styleUrls: ['./consult-modal.component.scss']
})
export class ConsultModalComponent {
  form = this._fb.group({
    name: ['', Validators.required],
    phone: ['', Validators.pattern(PHONE_REGEX)],
    email: ['', [Validators.required, Validators.email]],
    message: ['', Validators.required],
    privacy_policy: [false, Validators.requiredTrue]
  });

  private url = this._router.url.split('?').shift();
  loadingIcon = faCircleNotch;
  checkicon = faCheck;
  personalDataAgreementFile = personalDataAgreementFile;
  loading = false;
  success = false;
  error;

  constructor(
    public activeModal: NgbActiveModal,
    private _httpHelper: HttpHelperService,
    private _fb: FormBuilder,
    private _analytics: AnalyticService,
    private _router: Router
  ) {
    this._analytics.eventYandex('consultModal_open', { params: { url: this.url } });
  }

  submit() {
    if (this.form.invalid) return false;

    this.loading = true;
    this.error = null;

    this._analytics.eventYandex('consultModal_submit', { params: { data: this.form.value, url: this.url } });

    this._httpHelper.sendConsultRequest(this.form.value)
      .subscribe(
        () => {
          this.loading = false;
          this.success = true;
        },
        error => {
          console.log(error);
          this.loading = false;
          this.success = false;
          this.error = error;
        }
      );
  }
}

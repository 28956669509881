import { SafeHtml } from '@angular/platform-browser';

export enum ClientServiceType {
  FACTORING = 1,
  LEASING,
  CREDIT,
  GUARANTEE
}

export type ServiceTypes = 'factoring' | 'leasing' | 'credit' | 'bg';

export interface IConsultRequestData {
  name: string;
  email: string;
  phone?: string;
  message?: string;
}

export interface IConsultResponseData {
  result: string;
}

export interface IFastRegisterRequestData {
  service: number;
  first_name: string;
  email: string;
  inn: string;
  mobile_phone: string;
  sum?: number;
  limitation?: number;
  credit_term?: number;
  sms_code?: string;
}

export interface IFastRegisterResponseData {
  id: number;
  token: string;
  ua: {
    id: number;
    type: ServiceTypes;
  };
}

export interface PageComponentMeta {
  title?: string;
  description?: string;
}

interface IDocType {
  id: number;
  label: string;
}

interface IDocTypeDetailed extends IDocType {
  help_text: string | null;
  mime: string | null;
  required: boolean;
}

export interface IClientService {
  id: number;
  name: string;
  type: ClientServiceType;
  hide_second_inn: boolean;
}

export interface IClientServiceDetailed extends IClientService {
  doc_types: IDocTypeDetailed[];
  formulas: IFormula[];
}

export interface IFormulaField {
  label: string;
  min: number;
  max: number;
  step: number;
  multiplier: number;
}

export interface IFormulaResult {
  label: string;
  result: string;
  unit: string;
}

export interface IFormula {
  fields: IFormulaField[];
  results: IFormulaResult[];
}

export interface CalcField {
  name: string;
  label: string;
  min?: number;
  max?: number;
  step?: number;
  multiplier?: number;
}

export interface CalcResult {
  name: string;
  label: string;
  result: (fields: {[field: string]: number},
           results: {[result: string]: number}) => number;
  unit: string;
  prefix?: string;
  disclaimer?: string;
}

export interface CalcRule {
  cond: (IClientService) => boolean;
  fields: CalcField[];
  results: CalcResult[];
}

export interface FaqEntry {
  content: string;
  head: string;
  id: number;
  lead: string;
  safeContent?: SafeHtml;
  safeLead?: SafeHtml;
}

export interface Article {
  id: string;
  name: string;
  slug: string;
  url: string;
  description: string;
  body: string;
  children: Article[];
}

export enum ArticleType {
  digital = 'digital_service',
  additional = 'additional_service'
}

<div class="calculator mb-3">
  <div class="fields">
    <form>
      <label for="calculatorService" class="mb-2">Выберите услугу:</label>
      <select
        id="calculatorService"
        class="form-control mb-5"
        name="service"
        [disabled]="servicesLoading"
        [(ngModel)]="selectedService"
        (ngModelChange)="serviceChanged()"
      >
        <option *ngFor="let serviceItem of services" [ngValue]="serviceItem">{{ serviceItem.name }}</option>
      </select>

      <ng-container *ngFor="let field of rule.fields">
        <label [attr.for]="field.name">{{ field.label }}</label>
        <div class="row mb-4">
          <div class="form-group col inputs">
            <input type="text"
                   class="form-control no-appearance"
                   appFormat
                   formatType="integer"
                   inputmode="numeric"
                   [separate]="true"
                   [id]="field.name"
                   [name]="field.name"
                   [min]="field.min"
                   [max]="field.max"
                   [disabled]="isCalcDisabled"
                   [(ngModel)]="calcFields[field.name]"
                   (ngModelChange)="calc()"
            />
          </div>
          <div class="form-group col-8 d-none d-md-block">
            <input type="range"
                   [attr.aria-label]="'Ползунок ' + field.label"
                   class="custom-range"
                   [name]="field.name"
                   [step]="field.step || 1"
                   [min]="field.min"
                   [max]="field.max || 10000"
                   [attr.disabled]="isCalcDisabled ? 'disabled' : null"
                   [(ngModel)]="calcFields[field.name]"
                   (ngModelChange)="calc()"
            />
          </div>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="result text-dark" [class.disabled]="isCalcDisabled">
    <div class="result-section" *ngFor="let result of rule.results">
      <small>{{ result.label }}, {{ result.unit }}</small>
      <span class="sum">{{ result.prefix || "" }}{{ calcResults[result.name] | septhousand }}</span>
    </div>
  </div>
</div>

<div class="bottom-row">
  <div class="button">
    <a [href]="'/account/requests/create' | lk" target="_blank" class="btn btn-dark" [class.disabled]="isCalcDisabled" rel="noopener">Подать заявку</a>
  </div>

  <small>Расчет является предварительным<br />и не является конечным предложением</small>
</div>

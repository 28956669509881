import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsultModalComponent } from './consult-modal/consult-modal.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    ConsultModalComponent,
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class ModalsModule { }

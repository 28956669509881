import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { MetaDataService } from './services/meta-data.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  hideLayout = false;
  metrikaId = environment.yaMetrikaId;

  constructor(
    private readonly _metaData: MetaDataService,
    @Inject(DOCUMENT) private readonly _document: HTMLDocument
  ) { }

  ngOnInit(): void {
    this._metaData.appendImageLinks(this._document.location.origin);
  }
}

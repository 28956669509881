import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import {
  IClientService,
  IConsultRequestData,
  IConsultResponseData,
  IFastRegisterRequestData,
  IFastRegisterResponseData
} from '../types';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {
  private _services?: IClientService[];

  constructor(private _http: HttpClient) { }

  sendConsultRequest(data: IConsultRequestData): Observable<IConsultResponseData> {
    return this._http.post<IConsultResponseData>(environment.apiEndpoint + '/send_message', data);
  }

  fastRegister(path: string, data: IFastRegisterRequestData): Observable<IFastRegisterResponseData> {
    return this._http.post<IFastRegisterResponseData>(`${environment.apiEndpoint}/users/${path}`, data);
  }

  checkEmailAvailable(email: string): Observable<boolean> {
    return this._http.post(`${environment.apiEndpoint}/users/factoring`, { email })
      .pipe(
        map(response => !!response),
        catchError(error => of(!('email' in error.error)))
      );
  }

  getServices(): Observable<IClientService[]> {
    return this._services
      ? of(this._services)
      : this._http.get<IClientService[]>(environment.apiEndpoint + '/services')
        .pipe(tap(s => this._services = s));
  }
}

import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LogoComponent } from './components/logo/logo.component';
import { CommonModule } from '@angular/common';
import { YaMapComponent } from './components/ya-map/ya-map.component';
import { YaMapModule } from './components/ya-map/ya-map.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import {
  NgbDateAdapter,
  NgbDateNativeAdapter, NgbDateParserFormatter,
  NgbDatepickerModule, NgbDropdownModule,
  NgbPopoverModule,
  NgbProgressbarModule
} from '@ng-bootstrap/ng-bootstrap';
import { SepthousandPipe } from './pipes/septhousand.pipe';
import { InputFormatDirective } from './directives/input-format/input-format.directive';
import { BgTextComponent } from './components/bg-text/bg-text.component';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { ConsultBlockComponent } from './components/consult-block/consult-block.component';
import { FullWidthSpoilerComponent } from './components/full-width-spoiler/full-width-spoiler.component';
import { HeaderDropdownComponent } from './components/header-dropdown/header-dropdown.component';
import { HowItWorksListComponent } from './components/how-it-works-list/how-it-works-list.component';
import { PartnersBlockComponent } from './components/partners-block/partners-block.component';
import { OpenConsultDirective } from './directives/open-consult/open-consult.directive';
import { CustomDateParserFormatter } from '../services/custom-date-parser-formatter.service';
import { BgTextContactsComponent } from './components/bg-text-contacts/bg-text-contacts.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LkPipe } from './pipes/lk.pipe';
import { FastOfferFormComponent } from './components/fast-offer-form/fast-offer-form.component';

@NgModule({
  declarations: [
    LogoComponent,
    HeaderComponent,
    FooterComponent,
    HeaderMenuComponent,
    SepthousandPipe,
    BgTextComponent,
    CalculatorComponent,
    ConsultBlockComponent,
    FullWidthSpoilerComponent,
    HeaderDropdownComponent,
    HowItWorksListComponent,
    PartnersBlockComponent,
    BgTextContactsComponent,

    // Directives
    InputFormatDirective,
    OpenConsultDirective,

    // Pipes
    SepthousandPipe,
    LkPipe,
    FastOfferFormComponent
  ],
  imports: [
    FontAwesomeModule,
    CommonModule,
    YaMapModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbProgressbarModule,
    NgbDatepickerModule,
    NgbPopoverModule,
    MatFormFieldModule,
    MatInputModule,
    NgbDropdownModule
  ],
  exports: [
    LogoComponent,
    HeaderComponent,
    FooterComponent,
    HeaderMenuComponent,
    YaMapComponent,
    BgTextComponent,
    CalculatorComponent,
    ConsultBlockComponent,
    FullWidthSpoilerComponent,
    HeaderDropdownComponent,
    HowItWorksListComponent,
    PartnersBlockComponent,
    BgTextContactsComponent,

    // Directives
    InputFormatDirective,
    OpenConsultDirective,

    // Pipes
    SepthousandPipe,
    LkPipe,
    FastOfferFormComponent
  ],
  providers: [
    SepthousandPipe,
    LkPipe,
    [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}],
    [{provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}]
  ]
})
export class SharedModule { }

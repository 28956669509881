<div class="container">
  <header>
    <div class="header-bg" [class.shadow]="shadowed" [class]="bgClass"></div>
    <div class="header-inset">
      <app-logo class="mr-3 mr-md-5"></app-logo>
      <button type="button" class="mr-3 mr-md-4 header-button btn-menu" aria-label="Меню" (click)="toggleMenu()"></button>

      <app-header-dropdown text="Услуги" class="d-none d-md-inline-block small font-weight-bold">
        <p><a routerLink="/factoring">Факторинг</a></p>
        <p><a routerLink="/leasing">Лизинг</a></p>
        <p><a routerLink="/credit">Кредит</a></p>
        <p><a routerLink="/bank-guarantee" class="mb-0">Банковская гарантия</a></p>
      </app-header-dropdown>

      <app-header-dropdown link="/additional-services" text="Дополнительные услуги" class="d-none d-lg-inline-block small font-weight-bold">
        <ng-container *ngIf="articlesAdditional$ | async as articles">
          <p *ngFor="let article of articles; index as i">
            <a [routerLink]="[additionalServicesLink, article.url]" [class.mb-0]="i === articles.length - 1">{{ article.name }}</a>
          </p>
        </ng-container>
      </app-header-dropdown>

      <app-header-dropdown link="/about" text="О нас" class="d-none d-md-inline-block small font-weight-bold">
        <p><a routerLink="/partners">Партнёры</a></p>
        <p><a routerLink="/faq">Вопросы и ответы</a></p>
        <p class="mb-0"><a routerLink="/contacts">Контакты</a></p>
      </app-header-dropdown>

      <div class="flex-grow-1 text-right">
        <a [href]="'/account/factoring' | lk" target="_blank" rel="noopener" class="btn btn-sm btn-dark">Войти</a>
      </div>
    </div>
    <div class="header-menu">
      <div class="row" style="height: 100%">
        <div class="col d-flex flex-column align-items-start">
          <p class="large font-weight-bold"><a routerLink="/factoring" class="text-decoration-none">Факторинг</a></p>
          <p class="large font-weight-bold"><a routerLink="/leasing" class="text-decoration-none">Лизинг</a></p>
          <p class="large font-weight-bold"><a routerLink="/credit" class="text-decoration-none">Кредит</a></p>
          <p class="large font-weight-bold"><a routerLink="/bank-guarantee" class="text-decoration-none">Банковская гарантия</a></p>

          <div class="d-md-none">
            <p class="large"><a [routerLink]="additionalServicesLink" class="text-decoration-none">Доп. услуги</a></p>
            <p class="large"><a routerLink="/about" class="text-decoration-none">О нас</a></p>
            <p class="large"><a routerLink="/partners" class="text-decoration-none">Партнёры</a></p>
            <p class="large"><a routerLink="/faq" class="text-decoration-none">Вопросы и ответы</a></p>
            <p class="large"><a routerLink="/contacts" class="text-decoration-none">Контакты</a></p>
          </div>

          <p class="d-none d-md-block large mt-auto">
            <a [href]="'tel:' + supportPhone" class="text-decoration-none">{{ supportPhoneFormat }}</a>
          </p>
          <p class="d-none d-md-block large"><a [href]="'mailto:' + supportEmail" class="text-decoration-none">{{ supportEmail }}</a></p>

          <button type="button" class="mt-auto mt-md-0 btn btn-success consult-button" appOpenConsult>Получить консультацию</button>
        </div>
        <div class="col d-none d-md-flex flex-column align-items-start">
          <p class="large"><a [routerLink]="additionalServicesLink" class="text-decoration-none">Доп. услуги</a></p>
          <p class="large"><a routerLink="/about" class="text-decoration-none">О нас</a></p>
          <p class="large"><a routerLink="/partners" class="text-decoration-none">Партнёры</a></p>
          <p class="large"><a routerLink="/faq" class="text-decoration-none">Вопросы и ответы</a></p>
          <p class="large"><a routerLink="/contacts" class="text-decoration-none">Контакты</a></p>

          <p class="small mt-auto mb-5">
            © 2020 Все права защищены и охраняются законом. Использование материалов сайта
            разрешено только с письменного разрешения ООО «МайФи». Данный интернет-сайт
            носит исключительно информационный характер и не является публичной офертой.
          </p>
          <a [href]="personalDataAgreementFile">Политика обработки персональных данных</a>
        </div>
      </div>
    </div>
  </header>
</div>

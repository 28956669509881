<form class="form d-flex align-items-md-end flex-column flex-md-row" [formGroup]="form">
  <ng-container [ngSwitch]="step">
    <ng-container *ngSwitchCase="0">
      <div class="form-group mr-md-2 mb-2">
        <label class="small mb-2">Срок (мес):</label>
        <input type="text"
               class="form-control term-input-width border-0"
               formControlName="term"
               appFormat
               min="1"
               [class.is-invalid]="form.get('term').touched && form.get('term').invalid" />
      </div>
      <div class="form-group mr-md-2 mb-2">
        <label class="small mb-2">Сумма:</label>
        <input type="text"
               class="form-control sum-input-width border-0"
               formControlName="sum"
               appFormat
               [separate]="true"
               [class.is-invalid]="form.get('sum').touched && form.get('sum').invalid" />
      </div>
      <div class="mb-2">
        <button class="btn btn-block" [class]="finishBtnClass" [disabled]="form.get('term').invalid || form.get('sum').invalid" (click)="next()">Получить предложение</button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="1">
      <div class="form-group mr-md-2 mb-2 min-width">
        <label class="small mb-2">ИНН компании:</label>
        <input class="form-control border-0"
               placeholder="123456789012"
               formControlName="inn"
               [class.is-invalid]="form.get('inn').touched && form.get('inn').invalid" />
      </div>
      <div class="mb-2">
        <button class="btn btn-block" [class]="forGuarantee ? nextBtnClass : finishBtnClass" [disabled]="form.get('inn').invalid" (click)="enterInn()">{{ forGuarantee ? 'Дальше' : 'Получить предложение' }}</button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="2">
      <div class="service-selector">
        <label class="small mb-2">Какой продукт вас интересует?</label>
        <div class="d-flex flex-column flex-md-row">
          <div class="mr-md-2 mb-2">
            <button
              class="btn btn-secondary btn-block"
              (click)="selectService(ClientServiceType.FACTORING)">Факторинг</button>
          </div>
          <div class="mr-md-2 mb-2">
            <button
              class="btn btn-secondary btn-block"
              (click)="selectService(ClientServiceType.LEASING)">Лизинг</button>
          </div>
          <div class="mr-md-2 mb-2">
            <button
              class="btn btn-secondary btn-block"
              (click)="selectService(ClientServiceType.CREDIT)">Кредит</button>
          </div>
          <div>
            <button
              class="btn btn-secondary btn-block"
              (click)="selectService(ClientServiceType.GUARANTEE)">Банковская гарантия</button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="3">
      <div class="form-group mr-md-2 mb-2 min-width">
        <label class="small mb-2">ФИО:</label>
        <input type="text"
               name="name"
               class="form-control border-0"
               formControlName="first_name"
               [class.is-invalid]="form.get('first_name').touched && form.get('first_name').invalid" />
      </div>
      <div class="mb-2">
        <button class="btn btn-block" [class]="nextBtnClass" (click)="next()">Далее</button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="4">
      <div class="form-group mr-md-2 mb-2 min-width">
        <label class="small mb-2">Электронная почта:</label>
        <input type="email"
               name="email"
               class="form-control border-0"
               formControlName="email"
               [class.is-invalid]="form.get('email').touched && form.get('email').invalid" />
      </div>
      <div class="mb-2">
        <button class="btn btn-block"
                [class]="nextBtnClass"
                [disabled]="loading"
                (click)="checkEmail()">Далее<fa-icon *ngIf="loading" class="ml-1" [icon]="faCircleNotch" size="sm" [spin]="true"></fa-icon></button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="5">
      <div class="form-group mr-md-2 mb-2 min-width">
        <label class="small mb-2">Номер телефона:</label>
        <input type="text"
               name="mobile_phone"
               class="form-control border-0"
               formControlName="mobile_phone"
               [disabled]="loading"
               [class.is-invalid]="form.get('mobile_phone').touched && form.get('mobile_phone').invalid" />
      </div>
      <div class="mb-2">
        <button class="btn btn-block"
                [class]="nextBtnClass"
                [disabled]="form.get('mobile_phone').invalid || loading"
                (click)="send(true)">Далее<fa-icon *ngIf="loading" class="ml-1" [icon]="faCircleNotch" size="sm" [spin]="true"></fa-icon></button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="6">
      <div class="form-group mr-md-2 mb-2">
        <label class="small mb-2">Код из смс:</label>
        <input type="text"
               autofocus
               class="form-control code-input border-0"
               formControlName="sms_code"
               [class.is-invalid]="form.get('sms_code').touched && form.get('sms_code').invalid" />
      </div>
      <div class="mb-2">
        <button class="btn btn-block"
                [class]="finishBtnClass"
                [disabled]="form.get('sms_code').invalid || loading"
                (click)="send()">Перейти в личный кабинет<fa-icon *ngIf="loading" class="ml-1" [icon]="faCircleNotch" size="sm" [spin]="true"></fa-icon></button>
      </div>
    </ng-container>
  </ng-container>
</form>

<div class="help-block text-danger mt-1" *ngIf="step == 1 && form.get('inn').touched && form.get('inn').errors?.required">Необходимо заполнить</div>
<div class="help-block text-danger mt-1" *ngIf="step == 3 && form.get('first_name').touched && form.get('first_name').errors?.required">Необходимо заполнить</div>
<div class="help-block text-danger mt-1" *ngIf="step == 4 && form.get('email').touched && form.get('email').errors?.required">Необходимо заполнить</div>
<div class="help-block text-danger mt-1" *ngIf="step == 5 && form.get('mobile_phone').touched && form.get('mobile_phone').errors?.required">Необходимо заполнить</div>
<div class="help-block text-danger mt-1" *ngIf="step == 6 && form.get('sms_code').touched && form.get('sms_code').errors?.required">Необходимо заполнить</div>

<div class="help-block text-danger mt-1" *ngIf="step == 1 && form.get('inn').touched && form.get('inn').errors?.pattern">Некорректный ИНН</div>
<div class="help-block text-danger mt-1" *ngIf="step == 4 && form.get('email').touched && form.get('email').errors?.email">Некорректный email</div>
<div class="help-block text-danger mt-1" *ngIf="step == 5 && form.get('mobile_phone').touched && form.get('mobile_phone').errors?.pattern">Некорректный телефон</div>

<div class="help-block text-danger mt-1" *ngIf="error">{{ error }}</div>
<div class="help-block mt-1" *ngIf="needLogin"><a [href]="accountUrl">Войти в личный кабинет</a></div>

<div class="small mt-1" *ngIf="step == 3">Сейчас мы создадим для вас личный кабинет,<br />в котором сразу покажем предложения от банков.</div>
<div class="small mt-1" *ngIf="step == 4 && !error">Обещаем писать только по делу.</div>
<div class="small mt-1" *ngIf="step == 5">Номер телефона нужен, чтобы не потерять доступ к личному<br />кабинету, и для подписания документов.</div>
<div class="small mt-1" *ngIf="step == 6">
  <div>СМС с кодом отправлено на номер {{ form.get('mobile_phone').value }}.</div>

  <ng-template #resend><a href="javascript:void(0)" (click)="send(true, true)">Отправить ещё раз</a></ng-template>
  <ng-container *ngIf="smsTimer$ | async as timeout else resend">Отправить ещё раз можно будет через {{ timeout }}</ng-container>
</div>

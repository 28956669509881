import {
  AfterViewInit,
  Directive,
  forwardRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewContainerRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

const legitKeys = ['Delete', 'Backspace', 'Enter', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Home', 'End', 'Tab'];

export type InputFormatType = 'integer' | 'float';

@Directive({
  selector: 'input[appFormat]',
  exportAs: 'appFormat',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFormatDirective),
      multi: true,
    },
  ],
})
export class InputFormatDirective implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() formatType: InputFormatType = 'integer';
  @Input() separate = false;
  @Input() min: number = null;
  @Input() max: number = null;
  @Input() fixPoint: number = null;
  @Input() fitFontSize = false;

  private _parseFn: (input: string, radix?: number) => number = null;

  private _disabled = false;
  private _focused = false;
  private _initFontSize: number;
  private _initMinHeight: number;

  private _onChange: (value: string | number) => void = () => {};
  private _onTouched: () => void = () => {};

  constructor(
    private readonly _view: ViewContainerRef,
    private readonly _renderer: Renderer2,
    private readonly _http: HttpClient,
    @Inject(PLATFORM_ID)
    private readonly _platformId: Object
  ) {}

  ngOnInit(): void {
    switch (this.formatType) {
      case 'float': this._parseFn = parseFloat; break;
      case 'integer': this._parseFn = parseInt; break;
    }

    if (isPlatformBrowser(this._platformId)) {
      const style = getComputedStyle(this.element, null);
      this._initFontSize = parseInt(style.fontSize, 10);
      this._initMinHeight = parseInt(style.height, 10);
    }
  }

  ngAfterViewInit(): void {
    this._disabled = this.element.getAttribute('disabled') === null;
  }

  registerOnChange(fn: any): void {
    this._onChange = (value) => {
      fn(value);
    };
  }
  registerOnTouched(fn: any): void { this._onTouched = fn; }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this._renderer.setAttribute(this.element, 'disabled', 'disabled');
    } else {
      this._renderer.removeAttribute(this.element, 'disabled');
    }
  }

  writeValue(obj: any): void {
    if (obj === null || obj === undefined) {
      obj = '';
    }

    this.setValue(obj.toString());
  }

  @HostListener('keydown', ['$event'])
  keydown($event: KeyboardEvent) {
    const input = $event.key;
    const isSelectedAll = this._selectionLength() === this.rawValue.length;

    let rawValue = this.rawValue;
    let len = rawValue.length;
    let selStart = this.element.selectionStart;
    let selEnd = this.element.selectionEnd;

    const reset = () => {
      rawValue = this.rawValue = '';
      len = 0;
      selStart = 0;
      selEnd = 0;
    };

    if ($event.ctrlKey || $event.metaKey || $event.altKey || legitKeys.includes(input)) {
      return true;
    }

    switch (this.formatType) {
      case 'integer':
        if (!/[\d-]/.test(input)) {
          return false;
        }

        if (isSelectedAll) {
          reset();
        }

        if (input === '0' && (rawValue === '0' || len !== 0 && selStart === 0)) {
          return false;
        }

        if (rawValue === '0' && selStart !== 0) {
          reset();
        }

        break;

      case 'float':
        if (!/[\d,.-]/.test(input)) {
          return false;
        }

        if (isSelectedAll) {
          reset();
        }

        if (rawValue === '0' && selStart !== 0 && !'.,'.includes(input)) {
          return false;
        }

        if (input === '0' && (rawValue === '0' || len !== 0 && selStart === 0)) {
          return false;
        }

        if (',.'.includes(input)) {
          if (len !== 0 && !rawValue.includes('.') && selStart !== 0) {
            this._pasteRaw('.');
            this.rawValue = this.rawValue
              .replace(/\s*\.\s*/g, '.') // remove spaces around point
              .split('.')
              .map((value, index) => index ? value.replace(' ', '') : value)
              .join('.');
            this.element.selectionStart = selStart + 1;
            this.element.selectionEnd = selEnd + 1;
          }

          this.input();
          return false;
        }

        break;
    }

    return !(input === '-' && (selStart !== 0 || rawValue.includes('-')));
  }

  @HostListener('blur')
  blur() {
    if (!this._focused) { return; } // Without this safari generates a hundreds of blur events. Idk why.

    this.normalizeValue();
    this._onTouched();
    this._focused = false;
  }

  @HostListener('focus')
  focus() {
    this.element.select();

    // safari fix
    this._focused = true;
  }

  @HostListener('input')
  input() {
    const raw = this.rawValue;

    switch (this.formatType) {
      case 'integer':
        // Check integer number is correct
        if (/^-?(\d+\s?)*$/.test(raw)) {
          this.setValue(this.getValue());
        }
        break;
      case 'float':
        // Check number doesnt ends with point or zero in fraction part.
        if (/^-?(\d+\s?)*(\.\d*[1-9])?$/.test(raw)) {
          this.setValue(this.getValue());
        }
    }
    return true;
  }

  @HostListener('paste', ['$event'])
  paste($event: ClipboardEvent) {
    const data = $event.clipboardData.getData('text')
      .replace(/[^\d.,]/g, '')
      .replace(',', '.');

    const val = this._parseFn(data, 10);

    if (!isNaN(val)) {
      this._pasteRaw(val);
    }

    this.input();

    $event.preventDefault();
  }

  get rawValue(): string {
    return this.element.value;
  }

  set rawValue(val: string) {
    this.element.value = val;

    if (this.fitFontSize) {
      this._fitValue(val);
    }
  }

  get element(): HTMLInputElement {
    return this._view.element.nativeElement;
  }

  getValue(): string | number {
    let val;

    if (!this.rawValue) {
      return '';
    }

    switch (this.formatType) {
      case 'integer':
        val = parseInt(this.rawValue.replace(/\s/g, ''), 10);
        break;
      case 'float':
        val = parseFloat(this.rawValue.replace(/\s/g, ''));
    }

    return isNaN(val) ? this.rawValue : val;
  }

  setValue(val: string | number) {
    if ([null, undefined].includes(val)) {
      this.rawValue = '';
      return;
    }

    val = val.toString();

    switch (this.formatType) {
      case 'integer':
        val = parseInt(val.replace(/\s/g, ''), 10);
        this._setNumericValue(val);
        break;
      case 'float':
        val = parseFloat(val.replace(/\s/g, ''));
        this._setNumericValue(val);
        break;
    }
  }

  normalizeValue() {
    this.setValue(this._normalizeNumber(parseFloat((this.getValue() || 0).toString())));
  }

  private _fitValue(value: number | string) {
    const style = getComputedStyle(this.element, null);
    const innerWidth = this.element.clientWidth - parseInt(style.paddingLeft, 10) - parseInt(style.paddingRight, 10);
    const fontSize = parseInt(style.fontSize, 10);

    const div = document.createElement('div');
    div.style.fontSize = style.fontSize;
    div.style.fontFamily = style.fontFamily;
    div.style.letterSpacing = style.letterSpacing;
    div.style.wordSpacing = style.wordSpacing;
    div.style.position = 'absolute';
    div.style.top = '-999px';
    div.style.left = '-999px';
    div.innerHTML = value.toString();

    document.body.append(div);
    const textWidth = div.offsetWidth;
    div.remove();

    let newFontSize = Math.floor(fontSize * (innerWidth / textWidth));

    if (innerWidth < textWidth) {
      this.element.style.fontSize = newFontSize + 'px';
      this.element.style.minHeight = this._initMinHeight + 'px';
    } else if (fontSize < this._initFontSize) {
      if (newFontSize > this._initFontSize) {
        newFontSize = this._initFontSize;
      }
      this.element.style.fontSize = newFontSize + 'px';
    }
  }

  private _setNumericValue(val: number) {
    if (!isNaN(val)) {
      let pos = this.element.selectionStart;

      if (this.separate) {
        const whitespaces = this._whitespacesBeforeCursor();
        this.rawValue = this._separateNumber(val);
        pos += this._whitespacesBeforeCursor() - whitespaces;
      } else {
        this.rawValue = val.toString();
      }

      this.element.selectionStart = this.element.selectionEnd = pos;
      this._onChange(this.getValue());
    }
  }

  private _separateNumber(num: number): string {
    const isFloat = num % 1 !== 0;
    const isNegative = num < 0;
    let val = Math.abs(num).toString();
    let fract = '0';

    if (isFloat) {
      [ val, fract ] = val.split('.');
    }

    val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    if (isNegative) {
      val = '-' + val;
    }

    if (isFloat) {
      val += '.' + fract;
    }

    return val;
  }

  private _selectionLength(): number {
    return this.element.selectionEnd - this.element.selectionStart;
  }

  private _pasteRaw(value) {
    const { selectionStart, selectionEnd } = this.element;

    this.rawValue = this.rawValue.substr(0, selectionStart)
      + value + this.rawValue.substr(selectionEnd);

    this.element.selectionStart
      = this.element.selectionEnd
      = selectionStart + value.toString().length;

    this.element.selectionDirection = 'forward';
  }

  private _normalizeNumber(value: number): number {
    if (this.max !== null && value > this.max) {
      value = this.max;
    }

    if (this.min !== null && value < this.min) {
      value = this.min;
    }

    return value;
  }

  private _whitespacesBeforeCursor() {
    return this.rawValue.replace(/[^\s]/, '').length;
  }
}
